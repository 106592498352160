/* 
// 13. Contact
*/

.form-control:focus {
    box-shadow: none;
    background-color: $light;
    border-color: $primary;
}

.form-control {
    padding: 8px 10px;
    font-size: 14px;
    height: 40px;
    background-color: $gray-200;
    border: 1px solid darken($gray-200, 2%);
    &::placeholder {
        color: lighten($gray-600, 6%);
        font-size: 14px;
    }
}

#success_page {
    background-color: rgba($success, 0.25);
    padding: 10px 20px;
    border-radius: 4px;
}

.error_message {
    padding: 10px;
    margin-bottom: 20px;
    text-align: center;
    background-color: rgba($danger, 0.1);
    border-color: rgba($danger, 0.1);
    color: $danger;
    border-radius: 5px;
    font-size: 14px;
    
}
